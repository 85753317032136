@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fafafa;
  background-image: url("../public/images/background.png");
  background-size: contain;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
}

.modal {
  @apply w-screen md:w-[1100px] min-h-[500px];
}

.logo {
  background: #fff;
  margin: 0 auto;
  width: 70px;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  text-align: center;
}

.logo img {
  object-fit: cover;
  width: 80%;
  height: 80%;
}

.amount-info {
  border: 1px dashed #7fc3f7;
  background: #eff8ff;
  height: fit-content;
  padding: 10px 20px;
  font-weight: 700;
  border-radius: 26px;
  @apply max-w-[200px];
}

.payment-methods li {
  list-style: none;
  padding: 10px 10px 10px 10px;
  background: #fff;
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  /* justify-content: center; */
  align-items: center;
  @apply pl-10;
}

.payment-methods li:hover {
  color: #000;
  background: rgba(0, 149, 255, 0.13);
}

.payment-methods li.active {
  color: #000;
  background: rgba(0, 149, 255, 0.13);
}

.active {
  color: #000;
  background: rgba(0, 149, 255, 0.13);
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(10px, 10px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(30px, 30px);
  }
}

li span {
  padding: 5px;
}
li svg {
  padding: 10px;
  background: #ededed;
  color: #000;
  border-radius: 50%;
}

.payment-section {
  width: 400px;
  height: fit-content;
  background: #fff;
  @apply w-full md:w-[450px] min-h-[300px] mb-5 md:border-[#B9E2FF] md:border-[1px];
}

.receipt-modal {
  background: #fff;
  min-height: 300px;
  width: 400px;
  margin: 0 auto;
  /* padding: 10px 15px; */
  border-radius: 30px;
  -webkit-mask-image: radial-gradient(
    circle at top,
    transparent 30px,
    black 30px
  );
}
.receipt-modal-top {
  padding: 30px 15px 30px;
  object-fit: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  text-align: center;
}

.footer {
  display: flex;
  justify-content: space-between;
  gap: 0.6rem;
  padding: 20px;
}

.bg-transparent {
  border: 1px solid #0095ff;
  background: transparent;
  color: #0095ff;
}

table {
  background: #f6f6f6;
  border: none;
  width: 90%;
  padding: 10px;
  margin: 0 auto;
}
tr {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
